.MuiTablePaginationUnstyled-actions > button {
  padding-left: 10px;
  background: #673ab7;
  border-radius: 9px;
  color: white;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  font-size: 1.18rem;
}

.css-g1d714-ValueContainer > .css-1rhbuit-multiValue {
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 4px;
  padding-left: 2px;
  padding-right: 3px;
  background-color: #3f51b5;
}

/* .btn.btn-danger.redclosed {
  background-color: #e73d54;
  border-radius: 10px;
  border: none;
  box-shadow: 2px 3px 4px 0px #607d8b75;
} */

/* .btn.btn-danger.greenopen {
  background-color: #1aaf40;
  border-radius: 10px;
  border: none;
  box-shadow: 2px 3px 4px 0px #607d8b75;
}

.btn.btn-danger.greenopen::active {
  background-color: #1aaf40;
  border-radius: 10px;
  border: none;
  box-shadow: 2px 3px 4px 0px #607d8b75;
}
 */

/* div.col-xl-12.col-xxl-12 {
  margin-bottom: 150px;
}

div.col-md-6.text-black > .dropdown > .dropdown-menu.show {
  display: block;
  height: 35vh;
  overflow-y: auto;
}

button.actions-btn.btn.btn-light {
  background-color: #462b7c;
  color: white;
}



button.btn.btn-mcnu {
  background: rgb(127, 57, 251);
  border-width: 0px;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 0.55em 2em;
  margin-bottom: -57%;
  width: 100px;
}

.swal-text {
  text-align: center;
}

div.css-1rhbuit-multiValue {
  font-size: 16px;
}
 */

.csdivider {
  border-top: 1px solid lightgrey;
  margin-bottom: 25px;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #cddc39 0%,
    #00cdac 51%,
    #02aab0 100%
  );
}
.btn-grad {
  margin: 0px;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 4px 5px 4px 0px #9ff8f98f;
  border-radius: 10px;
  display: block;
  border: none;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

div.css-1rhbuit-multiValue {
  border-radius: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 19px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #08aeea;
  background-image: linear-gradient(0deg, #08aeea 0%, #27df8b 100%);
}

div.css-12jo7m5 {
  border-radius: 2px;
  color: hsl(0deg 0% 100%);
  font-size: 95%;
  overflow: hidden;
  padding: 2px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

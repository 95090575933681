button.actions-btn.btn.btn-light {
  display: none;
}

/* div.dropdown-menu.show {
  height: 30vh;
  overflow-y: auto;
}
 */

div.col-md-12.h50 > .dropdown > div.dropdown-menu.show {
  display: block;
  max-height: 26vh;
  overflow-x: auto;
}

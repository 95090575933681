.dropZoneOverlay, .FileUpload {
    width: 40px;
    height: 54px;
}

.dropZoneOverlay {

    font-family: cursive;
    color: #7066fb;
    position: absolute;
    top: 24px;
    text-align: center;
    
}

.FileUpload {
    opacity: 0;
    position: relative;
    z-index: 1;
}




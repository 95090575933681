div.cardheight {
  overflow: auto;
  white-space: nowrap;
}

div.card-body {
  font-size: 0.82rem;
  padding-left: 10px;
}

div.css-1hb7zxy-IndicatorsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
}

div.css-yk16xz-control {
  min-width: 200px;
}
div.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  min-width: 212px;
  font-size: 12px;
  margin-left: -9px;
}

.card-table td:first-child {
  padding-left: 15px;
}

.card-table td:last-child {
  padding-right: 8px;
}

div.col-md-12 {
  padding: 0px;
}

div.css-yk16xz-control {
  min-width: 200px;
  border-radius: 10px;
}

.css-yk16xz-control {
  border-radius: 10px;
}
.css-1pahdxg-control:hover {
  border-color: hsl(0, 0%, 70%) !important;
}
.css-1pahdxg-control:hover {
  border-color: hsl(0, 0%, 70%) !important;
}
.css-1pahdxg-control {
  border-color: hsl(0, 0%, 70%) !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  font-size: 0.85rem;
  border-bottom: 1px solid rgb(209, 209, 209);
  white-space: normal;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  background-color: rgba(86, 2, 134, 0.747);
  color: white;
  white-space: normal;
  font-weight: normal;
}

/* .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected::before {
  content: "\2713";
  margin-right: 5px;
}
 */
